import React, {Component} from "react";
import {databaseService} from "../../Services/services";
import DatabaseModel from "../../Models/DatabaseModel";
import Card from "../Shared/Card";
import {DatabaseIcon} from "@heroicons/react/outline";
import LoadingSpinner from "../Shared/LoadingSpinner";
import ILoading from "../Shared/ILoading";

class Databases extends Component<any, IState> {

    constructor(props: any) {
        super(props);

        this.state = {databases: [], isLoading: false}
    }

    async componentDidMount() {
        this.setState({isLoading: true});

        const databases: DatabaseModel[] = await databaseService.getAll();
        this.setState({databases: databases, isLoading: false});
    }

    render() {
        return (
            <div>
                <div className="text-3xl mb-10">Databases</div>
                {this.state.isLoading && <LoadingSpinner/>}
                <div className="flex flex-wrap">
                    {this.state.databases.map((database, index) => {
                        const dateTime = new Date(database.created);
                        return (<Card key={index}
                                      icon={<DatabaseIcon className="w-8 h-8"/>}
                                      title={database.id} bodyContent={<div>Created: {dateTime.toLocaleDateString()} {dateTime.toLocaleTimeString()} </div>}
                            />)
                    })}
                </div>
            </div>
        );
    }
}
export default Databases;

interface IState extends ILoading {
    databases: DatabaseModel[];
}
