import React, { Component } from "react";
import DatabaseModel from "../../../Models/DatabaseModel";
import {
    databaseService,
    regionService,
    viewService,
} from "../../../Services/services";
import INavigationLink from "./INavigationLink";
import SidebarNavigationItem from "./SidebarNavigationItem";
import RegionTypeResponseModel from "../../../Models/RegionTypeResponseModel";
import ViewModel from "../../../Models/ViewModel";
import { UnregisterCallback } from "history";
import { RouteComponentProps, withRouter } from "react-router-dom";
class SidebarNavigation extends Component<IProps, IState> {
    private removeListener: UnregisterCallback;
    constructor(props: IProps) {
        super(props);
        this.state = { navigationLinks: [], activeRoute: "" };
    }

    componentDidMount() {
        this.constructNavigation();

        this.setState({ activeRoute: this.props.location.pathname });
        this.removeListener = this.props.history.listen((location) => {
            this.setState({ activeRoute: location.pathname });
        });
    }

    componentWillUnmount() {
        this.removeListener();
    }

    constructNavigation() {
        const databasesNavItemName = "Databases";

        let navigationLinks: INavigationLink[] = [
            {
                goTo: "/databases",
                name: databasesNavItemName,
                links: [],
                isCollapsible: true,
                load: () => this.loadDatabases(databasesNavItemName),
            },
            {
                goTo: "/",
                name: "Profile",
                links: [],
                isCollapsible: false,
                load: () => {},
            },
            {
                goTo: "/",
                name: "Security",
                links: [],
                isCollapsible: false,
                load: () => {},
            },
            {
                goTo: "/",
                name: "Log Off",
                links: [],
                isCollapsible: false,
                load: () => {},
            },
        ];
        this.setState({ navigationLinks: navigationLinks });
    }

    renderNavigationItem(links: INavigationLink[]) {
        return links.map((link, index) => {
            return (
                <div key={index}>
                    <SidebarNavigationItem
                        navigationLink={link}
                        elementKey={index}
                        indent={0}
                        activeRoute={this.state.activeRoute}
                        toggleSidebar={this.props.toggleSidebar}
                    />
                </div>
            );
        });
    }

    async loadDatabases(databasesNavItemName: string) {
        let navigationLinks = [...this.state.navigationLinks];
        const databaseNavigationItemIndex = navigationLinks.findIndex(
            (link) => link.name === databasesNavItemName
        );

        const databases: DatabaseModel[] = await databaseService.getAll();

        const databaseBaseRoute = "/databases";
        let databaseNavLinks: INavigationLink[] = databases.map((database) => {
            return {
                name: database.id,
                goTo: `${databaseBaseRoute}/${database.id}`,
                links: [],
                isCollapsible: true,
                load: () =>
                    this.loadRegionsAndTypes(
                        databaseBaseRoute,
                        database,
                        databaseNavigationItemIndex
                    ),
            };
        });
        databaseNavLinks.push({
            goTo: "/",
            name: "Create Database",
            links: [],
            isCollapsible: false,
            load: () => {},
        });

        navigationLinks[databaseNavigationItemIndex].links = databaseNavLinks;
        this.setState({ navigationLinks: navigationLinks });
    }

    async loadRegionsAndTypes(
        databaseBaseRoute: string,
        database: DatabaseModel,
        databaseNavigationItemIndex: number
    ) {
        let navigationLinks = [...this.state.navigationLinks];
        const regionNavigationItemIndex = navigationLinks[
            databaseNavigationItemIndex
        ].links.findIndex((link) => link.name === database.id);

        const regionAndTypesResponse: RegionTypeResponseModel =
            await regionService.getRegionsAndTypes(database.id);
        const regionBaseRoute = `${databaseBaseRoute}/${database.id}`;

        // noinspection UnnecessaryLocalVariableJS
        let regionNavLinks: INavigationLink[] = Array.from(
            new Set(
                regionAndTypesResponse.list.map(
                    (regionAndType) => regionAndType.region
                )
            )
        ).map((regionName) => {
            return {
                name: regionName,
                links: [],
                isCollapsible: true,
                goTo: `${regionBaseRoute}/${regionName}`,
                load: () =>
                    this.mapTypes(
                        regionAndTypesResponse,
                        database,
                        regionName,
                        regionBaseRoute,
                        databaseNavigationItemIndex,
                        regionNavigationItemIndex
                    ),
            };
        });

        navigationLinks[databaseNavigationItemIndex].links[
            regionNavigationItemIndex
        ].links = regionNavLinks;
        this.setState({ navigationLinks: navigationLinks });
    }

    async mapTypes(
        regionAndTypesResponse: RegionTypeResponseModel,
        database: DatabaseModel,
        regionName: string,
        regionBaseRoute: string,
        databaseNavigationItemIndex: number,
        regionNavigationItemIndex: number
    ) {
        let navigationLinks = [...this.state.navigationLinks];
        let typeNavigationItemIndex = navigationLinks[
            databaseNavigationItemIndex
        ].links[regionNavigationItemIndex].links.findIndex(
            (link) => link.name === regionName
        );

        const typeBaseRoute = `${regionBaseRoute}/${regionName}`;

        let typeNavLinks: INavigationLink[] = Array.from(
            new Set(regionAndTypesResponse.list)
        )
            .filter((regionAndType) => regionAndType.region === regionName)
            .map((regionAndType) => regionAndType.type)
            .map((typeName) => {
                return {
                    name: typeName,
                    links: [],
                    isCollapsible: true,
                    goTo: `${typeBaseRoute}/${typeName}`,
                    load: () =>
                        this.loadViews(
                            database,
                            regionName,
                            typeName,
                            typeBaseRoute,
                            databaseNavigationItemIndex,
                            regionNavigationItemIndex,
                            typeNavigationItemIndex
                        ),
                };
            });
        typeNavLinks.push({
            name: "Create new Type",
            goTo: "",
            links: [],
            isCollapsible: false,
            load: () => {},
        });

        navigationLinks[databaseNavigationItemIndex].links[
            regionNavigationItemIndex
        ].links[typeNavigationItemIndex].links = typeNavLinks;
        this.setState({ navigationLinks: navigationLinks });
    }

    async loadViews(
        database: DatabaseModel,
        regionName: string,
        typeName: string,
        typeBaseRoute: string,
        databaseNavItemIndex: number,
        regionNavItemIndex: number,
        typeNavItemIndex: number
    ) {
        let navigationLinks = [...this.state.navigationLinks];
        let viewNavItemIndex = navigationLinks[databaseNavItemIndex].links[
            regionNavItemIndex
        ].links[typeNavItemIndex].links.findIndex(
            (link) => link.name === typeName
        );

        const views: ViewModel[] = await viewService.getAll(
            database.id,
            database.key
        );
        const viewBaseRoute = `${typeBaseRoute}/${typeName}`;

        let viewNavLinks: INavigationLink[] = views
            .filter(
                (view) =>
                    view.targetRegion === regionName && view.type === typeName
            )
            .map((view) => {
                return {
                    name: view.id,
                    goTo: `${viewBaseRoute}/${view.id}`,
                    isCollapsible: false,
                    links: [],
                    load: () => {},
                };
            });
        viewNavLinks.push({
            name: "Create View",
            goTo: "",
            links: [],
            isCollapsible: false,
            load: () => {},
        });

        navigationLinks[databaseNavItemIndex].links[regionNavItemIndex].links[
            typeNavItemIndex
        ].links[viewNavItemIndex].links = viewNavLinks;
        this.setState({ navigationLinks: navigationLinks });
    }

    render() {
        return this.renderNavigationItem(this.state.navigationLinks);
    }
}
export default withRouter(SidebarNavigation);

interface IState {
    navigationLinks: INavigationLink[];
    activeRoute: string;
}

interface IProps extends RouteComponentProps {
    toggleSidebar: () => void;
}
