import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { databaseService } from "../../Services/services";
import DataTable from "react-data-table-component";
import LoadingSpinner from "../Shared/LoadingSpinner";
import { useDatabaseInfo } from "../../Contexts/DatabaseInfo";
import { useGlobal } from "../../Contexts/Global";
import { useTableData } from "../../Contexts/TableData";
import { flyoutAddTitle } from "../../Constants";

interface IProps extends RouteComponentProps<any> {}

export const Type: React.FC<IProps> = (props) => {
    const { updateDatabaseInfo, typeName, partitionBy, apiKey } =
        useDatabaseInfo();
    const { isLoading, setLoading, setFlyoutTitle, setFlyout } = useGlobal();
    const { columns, loadTableData, rows } = useTableData();

    useEffect(() => {
        (async () => {
            const {
                databaseId,
                regionName,
                typeName: typeNameFromParams,
            } = props.match.params;
            let dbApiKey = "";

            setLoading(true);

            if (!apiKey) {
                dbApiKey = (await databaseService.get(databaseId)).key;
            }

            updateDatabaseInfo({
                id: databaseId,
                apiKey: dbApiKey || apiKey,
                regionName,
                typeName: typeNameFromParams,
                partitionBy: "null",
            });

            if (partitionBy) {
                await loadTableData(true);
                setLoading(false);
            }
        })();
    }, [
        props.match.params,
        updateDatabaseInfo,
        setLoading,
        loadTableData,
        partitionBy,
        apiKey,
        typeName,
    ]);

    const addItemClick = () => {
        setFlyoutTitle(flyoutAddTitle);
        setFlyout(true);
    };

    return (
        <div>
            <div className="text-3xl mb-10">{typeName}</div>
            <div className="mb-5">
                <button
                    className="h-10 w-full md:w-40 rounded bg-gyxi-blue hover:bg-gyxi-blue-dark text-white "
                    onClick={addItemClick}
                >
                    Add
                </button>
            </div>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <DataTable
                    columns={columns}
                    data={rows}
                    customStyles={{
                        table: {
                            style: {
                                marginBottom: "80px",
                            },
                        },
                        headCells: {
                            style: {
                                paddingLeft: "24px",
                            },
                        },
                    }}
                />
            )}
        </div>
    );
};

export default withRouter(Type);
