import React, {Component} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import TypeModel from "../../Models/TypeModel";
import RegionTypeResponseModel from "../../Models/RegionTypeResponseModel";
import {regionService} from "../../Services/services";
import Card from "../Shared/Card";
import {PuzzleIcon} from "@heroicons/react/outline";
import ILoading from "../Shared/ILoading";
import LoadingSpinner from "../Shared/LoadingSpinner";

class Region extends Component<RouteComponentProps<any,any>, IState> {
    constructor(props: any) {
        super(props);

        this.state = {types: [], isLoading: false}
    }

    async componentDidMount() {
        const {databaseId, regionName} = this.props.match.params;
        await this.loadTypes(databaseId, regionName);
    }

    async componentDidUpdate(prevProps: Readonly<RouteComponentProps<any, any>>, prevState: Readonly<IState>, snapshot?: any) {
        const prevDatabaseId = prevProps.match.params['databaseId'];
        const prevRegionName = prevProps.match.params['regionName'];

        const {databaseId, regionName} = this.props.match.params;
        const didDatabaseRouteChange = prevDatabaseId && prevDatabaseId !== databaseId;
        const didRegionRouteChange = prevRegionName && prevRegionName !== regionName;

        if (didDatabaseRouteChange || didRegionRouteChange) {
            await this.loadTypes(databaseId, regionName);
        }
    }

    async loadTypes(databaseId: string, regionName: string) {
        this.setState({types: [], isLoading: true});

        const regionsAndTypesResponse: RegionTypeResponseModel = await regionService.getRegionsAndTypes(databaseId);
        const types: TypeModel[] = Array.from(
            new Set(regionsAndTypesResponse.list))
            .filter((regionAndType) => regionAndType.region === regionName)
            .map((regionAndType) => regionAndType.type)
            .map(typeName => {
                return {name: typeName}
            });
        this.setState({types: types, isLoading: false});
    }

    render() {
        const {regionName} = this.props.match.params;
        return (
            <div>
                <div className="text-3xl mb-10">{regionName}</div>
                {this.state.isLoading && <LoadingSpinner/>}
                <div className="flex flex-wrap">
                    {this.state.types.map((type, index) =>
                        <Card key={index} icon={<PuzzleIcon className="w-8 h-8"/>} bodyContent={<></>} title={type.name}/>
                    )}
                    {this.state.types.length === 0 && !this.state.isLoading &&<div className="w-full text-center">No Types</div>}
                </div>
            </div>
        );
    }
}
export default withRouter(Region);

interface IState extends ILoading {
    types: TypeModel[];
}
