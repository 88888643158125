import {Component} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";

class Card extends Component<IProps> {
    render() {
        return (
            <div className="w-80 mr-10 mb-8 h-60 bg-white rounded-lg shadow-xl transform hover:scale-105 hover:shadow-2xl p-10 flex flex-col transition-all duration-300 ease-in-out cursor-pointer"
                 onClick={() => this.props.history.push(`${this.props.history.location.pathname}/${this.props.title}`)}>
                <div className="text-gyxi-pink">{this.props.icon}</div>
                <div className="text-xl py-5">{this.props.title}</div>
                <div className="py-5 border-t border-gray-200">{this.props.bodyContent}</div>
            </div>
        );
    }
}
export default withRouter(Card);

interface IProps extends RouteComponentProps {
    icon: JSX.Element;
    title: string;
    bodyContent: JSX.Element;
}
