import React, { Component } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Container from "../Container/Container";
import { BrowserRouter as Router } from "react-router-dom";
import Flyout from "../Shared/Flyout";
import { GlobalProvider } from "../../Contexts/Global";
import { TableDataProvider } from "../../Contexts/TableData";

class Base extends Component {
    render() {
        return (
            <Router>
                <div className="relative md:flex font-sans">
                    <Sidebar />
                    <GlobalProvider>
                        <TableDataProvider>
                            <Container />
                            <Flyout />
                        </TableDataProvider>
                    </GlobalProvider>
                </div>
            </Router>
        );
    }
}
export default Base;
