import React, { Component } from "react";
import Collapsible from "react-collapsible";
import { RouteComponentProps, withRouter } from "react-router-dom";
import INavigationLink from "./INavigationLink";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import ILoading from "../../Shared/ILoading";

class SidebarNavigationItem extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { isOpened: false, dataLoaded: false, isLoading: false };
        this.toggle = this.toggle.bind(this);
    }

    async toggle(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();

        if (!this.state.isOpened && !this.state.dataLoaded) {
            this.setState({ isLoading: true });
            await this.props.navigationLink.load();
            this.setState({ dataLoaded: true, isLoading: false });
        }
        this.setState({ isOpened: !this.state.isOpened });
    }

    renderLoadingSpinner() {
        return (
            <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                />
                <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
            </svg>
        );
    }

    // Workaround to avoid class purge in prod build
    getIndentSize(indent: number) {
        if (indent === 0) {
            return "ml-0";
        } else if (indent === 4) {
            return "ml-4";
        } else if (indent === 8) {
            return "ml-8";
        } else if (indent === 12) {
            return "ml-12";
        } else if (indent === 16) {
            return "ml-16";
        } else {
            return "";
        }
    }

    goTo(path: string) {
        this.props.history.push(path);
        this.props.toggleSidebar();
    }

    render() {
        const isLinkActive =
            this.props.activeRoute !== "/" &&
            this.props.activeRoute === this.props.navigationLink.goTo;
        return (
            <div key={this.props.elementKey}>
                <div
                    className={`py-2 px-5 hover:bg-gyxi-pink hover:text-white rounded flex items-center justify-between cursor-pointer
                ${this.state.isOpened ? `bg-gray-200` : ""}
                ${isLinkActive ? `bg-gyxi-pink text-white` : ""}`}
                    onClick={() => this.goTo(this.props.navigationLink.goTo)}
                >
                    <div className={this.getIndentSize(this.props.indent)}>
                        {this.props.navigationLink.name}
                    </div>
                    <div className="flex items-center">
                        <div className="ml-2">
                            {this.state.isLoading &&
                                this.renderLoadingSpinner()}
                        </div>
                        {this.props.navigationLink.isCollapsible && (
                            <button
                                className="h-8 w-8 hover:text-gray-300"
                                onClick={this.toggle}
                            >
                                {!this.state.isOpened ? (
                                    <ChevronDownIcon className="h-6 w-6" />
                                ) : (
                                    <ChevronUpIcon className="h-6 w-6" />
                                )}
                            </button>
                        )}
                    </div>
                </div>
                <div
                    className={`border-b ${
                        !this.state.isOpened && !isLinkActive
                            ? "border-gray-200"
                            : "border-white"
                    }`}
                />
                {this.props.navigationLink.links.map((link, index) => {
                    return (
                        <div key={index}>
                            <Collapsible
                                trigger={""}
                                transitionTime={100}
                                open={this.state.isOpened}
                            >
                                <div>
                                    <SidebarNavigationItem
                                        navigationLink={link}
                                        elementKey={index}
                                        indent={this.props.indent + 4}
                                        activeRoute={this.props.activeRoute}
                                        history={this.props.history}
                                        location={this.props.location}
                                        match={this.props.match}
                                        toggleSidebar={this.props.toggleSidebar}
                                    />
                                </div>
                            </Collapsible>
                        </div>
                    );
                })}
            </div>
        );
    }
}
export default withRouter(SidebarNavigationItem);

interface IProps extends RouteComponentProps {
    navigationLink: INavigationLink;
    elementKey: number;
    indent: number;
    activeRoute: string;
    toggleSidebar: () => void;
}

interface IState extends ILoading {
    isOpened: boolean;
    dataLoaded: boolean;
}
