import { useCallback, useMemo } from "react";
import { flyoutEditTitle } from "../Constants";
import { useDatabaseInfo } from "../Contexts/DatabaseInfo";
import { useGlobal } from "../Contexts/Global";
import { itemService } from "../Services/services";

type UseMenuActions = () => {
    handleDelete: (id: string) => Promise<void>;
    handleEdit: (row: any) => void;
};

export const useMenuActions: UseMenuActions = () => {
    const {
        id: dbId,
        apiKey,
        regionName,
        typeName,
        partitionBy,
    } = useDatabaseInfo();
    const { setFlyout, setFlyoutData, setFlyoutTitle } = useGlobal();
    const handleDelete = useCallback(
        async (id: string) => {
            try {
                await itemService.deleteItem(
                    id,
                    dbId,
                    apiKey,
                    regionName,
                    typeName,
                    partitionBy
                );
            } catch (error) {
                console.error(error);
            }
        },
        [apiKey, dbId, regionName, typeName, partitionBy]
    );

    const handleEdit = useCallback(
        (row: any) => {
            setFlyoutData(row);
            setFlyoutTitle(flyoutEditTitle);
            setFlyout(true);
        },
        [setFlyout, setFlyoutData, setFlyoutTitle]
    );

    return useMemo(
        () => ({
            handleDelete,
            handleEdit,
        }),
        [handleDelete, handleEdit]
    );
};
