import IDatabaseService from "../Interfaces/IDatabaseService";
import DatabaseModel from "../../Models/DatabaseModel";
import httpClient from "../Http/httpClient";

class DatabaseService implements IDatabaseService {
    getAll(): Promise<DatabaseModel[]> {
        return httpClient.get<DatabaseModel[]>("https://master.gyxi.com/my/databases").then(response => response.data);
    }

    get(id: string): Promise<DatabaseModel> {
        // Workaround to get single database (since there is no get database endpoint)
        return httpClient.get<DatabaseModel[]>("https://master.gyxi.com/my/databases").then(response => response.data.filter(db => db.id === id)[0]);
    }
}
export default DatabaseService;
