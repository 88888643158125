import { Component } from "react";
import logo from "./../../Assets/logo.png";
import SidebarNavigation from "./Navigation/SidebarNavigation";
import { XIcon } from "@heroicons/react/outline";

class Sidebar extends Component<any, IState> {
    constructor(props: any) {
        super(props);
        this.state = { isSidebarCollapsed: false };
    }

    toggleSidebar() {
        this.setState({ isSidebarCollapsed: !this.state.isSidebarCollapsed });
    }

    render() {
        return (
            <div>
                <div className="bg-gray-300 text-gray-100 flex justify-between md:hidden items-center">
                    <div className="p-2 flex items-center">
                        <img src={logo} alt="Logo" />
                    </div>
                    <button
                        className="h-12 w-12 text-center focus:outline-none focus:bg-gray-400"
                        onClick={() => this.toggleSidebar()}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8 ml-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                </div>
                <div
                    className={`w-full md:w-80 p-2 z-10 bg-white border-r-2 overflow-auto border-gray-200 absolute min-h-screen inset-y-0 left-0 transform ${
                        this.state.isSidebarCollapsed ? "" : "-translate-x-full"
                    }
                md:relative md:translate-x-0 transition duration-200 ease-in-out`}
                >
                    <div className="p-4 flex justify-between">
                        <div className="flex items-center">
                            <div>
                                <img src={logo} alt="Logo" />
                            </div>
                        </div>
                        <div
                            className="md:hidden cursor-pointer"
                            onClick={() => this.toggleSidebar()}
                        >
                            <XIcon className="h-7 w-7 text-gray-500" />
                        </div>
                    </div>
                    <nav className="my-10 text-l">
                        <SidebarNavigation
                            toggleSidebar={this.toggleSidebar.bind(this)}
                        />
                    </nav>
                </div>
            </div>
        );
    }
}
export default Sidebar;

interface IState {
    isSidebarCollapsed: boolean;
}
