import { useCallback, useMemo } from "react";
import { useDatabaseInfo } from "../Contexts/DatabaseInfo";
import ItemResponseModel from "../Models/ItemResponseModel";
import { itemService } from "../Services/services";

type UseDatabaseActions = () => {
    getItems: () => Promise<ItemResponseModel>;
    saveItem: (payload: string) => Promise<SaveItemResponseModel>;
};

export const useDatabaseActions: UseDatabaseActions = () => {
    const {
        id: dbId,
        apiKey,
        regionName,
        typeName,
        partitionBy,
    } = useDatabaseInfo();

    const getItems = useCallback(async () => {
        return await itemService.getItems(
            dbId,
            apiKey,
            regionName,
            typeName,
            partitionBy
        );
    }, [apiKey, dbId, regionName, typeName, partitionBy]);

    const saveItem = useCallback(
        async (payload: string) => {
            return await itemService.saveItem(
                payload,
                dbId,
                apiKey,
                regionName,
                typeName,
                partitionBy
            );
        },
        [apiKey, dbId, regionName, typeName, partitionBy]
    );

    return useMemo(
        () => ({
            getItems,
            saveItem,
        }),
        [getItems, saveItem]
    );
};
