import React, { KeyboardEventHandler, useState } from "react";
import { useDatabaseInfo } from "../../Contexts/DatabaseInfo";
import { useTableData } from "../../Contexts/TableData";
import { itemService } from "../../Services/services";
import LoadingSpinner from "./LoadingSpinner";

export interface InputCellProps {
    id: string | number;
    fieldName: string;
    row: any;
    isReadOnly: boolean;
    shouldShowLoader: boolean;
}

const enterKey: string = "Enter";

export const InputCell: React.FC<InputCellProps> = ({
    id,
    fieldName,
    row,
    isReadOnly,
    shouldShowLoader,
}) => {
    const [value, setValue] = useState<string>(row[fieldName]);
    const { currentLoadingRowId, setCurrentLoadingRowId } = useTableData();
    const {
        id: databaseId,
        apiKey,
        regionName,
        typeName,
        partitionBy,
    } = useDatabaseInfo();

    const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (
        event
    ) => {
        setValue(event.target.value);
    };

    const onKeyDownHandler: KeyboardEventHandler<HTMLInputElement> = async (
        event
    ) => {
        if (event.key === enterKey) {
            event.currentTarget.blur();
            await updateFieldIfNeeded();
        }
    };

    const onBlurHandler: React.FocusEventHandler<HTMLInputElement> = async (
        event
    ) => {
        await updateFieldIfNeeded();
        event.target.blur();
    };

    const updateFieldIfNeeded = async (): Promise<void> => {
        const valueWithProperType =
            typeof row[fieldName] === "number" ? parseInt(value, 10) : value;
        if (row[fieldName] !== valueWithProperType) {
            setCurrentLoadingRowId(row.id);
            const payload = JSON.stringify({
                ...row,
                [fieldName]: valueWithProperType,
            });
            row[fieldName] = valueWithProperType;
            await itemService.saveItem(
                payload,
                databaseId,
                apiKey,
                regionName,
                typeName,
                partitionBy
            );
            setCurrentLoadingRowId(undefined);
        }
    };

    return (
        <>
            {shouldShowLoader && currentLoadingRowId === row.id ? (
                <div className="z-50 absolute">
                    <LoadingSpinner />
                </div>
            ) : null}
            <input
                className={`cell-${id} w-full outline-none rounded p-2 ${
                    !!currentLoadingRowId ? "text-gray-500 bg-transparent" : ""
                } ${
                    isReadOnly
                        ? "cursor-default"
                        : "focus:ring-2 focus:ring-gray-200"
                }`}
                onChange={onChangeHandler}
                onBlur={onBlurHandler}
                onKeyDown={onKeyDownHandler}
                value={value}
                readOnly={isReadOnly}
                disabled={!!currentLoadingRowId}
            />
        </>
    );
};
