import ViewModel from "../../Models/ViewModel";
import httpClient from "../Http/httpClient";
import {AxiosRequestConfig} from "axios";

class ViewService {
    getAll(databaseId: string, databaseApiKey: string): Promise<ViewModel[]> {
        const config: AxiosRequestConfig = {headers: {"Gyxi-ApiKey": databaseApiKey}};
        return httpClient
            .get<ViewModel[]>(`https://master.gyxi.com/${databaseId}/views`, config)
            .then(response => response.data);
    }
}
export default ViewService;
