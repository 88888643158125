import RegionTypeResponseModel from "../../Models/RegionTypeResponseModel";
import httpClient from "../Http/httpClient";
import IRegionService from "../Interfaces/IRegionService";

class RegionService implements IRegionService {
    getRegionsAndTypes(databaseId: string): Promise<RegionTypeResponseModel> {
        return httpClient
            .get<RegionTypeResponseModel>(`https://master.gyxi.com/my/databases/${databaseId}/types`)
            .then(response => response.data);
    }
}
export default RegionService;
