import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";
import DataTableHelper from "../Helpers/DataTableHelper";
import { useDatabaseActions } from "../hooks/useDatabaseActions";

interface TableDataState {
    rows: any[];
    columns: any[];
    loadTableData: (shouldAddActions: boolean) => Promise<void>;
    currentLoadingRowId?: string;
    setCurrentLoadingRowId: React.Dispatch<
        React.SetStateAction<string | undefined>
    >;
}

const TableDataContext = createContext<TableDataState>({} as TableDataState);

export const TableDataProvider: React.FC = ({ children }) => {
    const [rows, setRows] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const [currentLoadingRowId, setCurrentLoadingRowId] = useState<string>();
    const { getItems } = useDatabaseActions();

    const loadTableData = useCallback(
        async (shouldAddActions: boolean) => {
            const itemResponseModel = await getItems();
            const table = DataTableHelper.getTable(
                itemResponseModel.list,
                shouldAddActions
            );

            setRows(table.rows);
            setColumns(table.columns);
        },
        [getItems]
    );

    const value = useMemo(
        () => ({
            rows,
            columns,
            loadTableData,
            currentLoadingRowId,
            setCurrentLoadingRowId,
        }),
        [columns, currentLoadingRowId, loadTableData, rows]
    );

    return (
        <TableDataContext.Provider value={value}>
            {children}
        </TableDataContext.Provider>
    );
};

export const useTableData = () => {
    return useContext(TableDataContext);
};
