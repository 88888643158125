import React, {Component} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {regionService} from "../../Services/services";
import RegionTypeResponseModel from "../../Models/RegionTypeResponseModel";
import RegionModel from "../../Models/RegionModel";
import Card from "../Shared/Card";
import {GlobeIcon} from "@heroicons/react/outline";
import ILoading from "../Shared/ILoading";
import LoadingSpinner from "../Shared/LoadingSpinner";

class Database extends Component<RouteComponentProps<any,any>, IState> {

    constructor(props: any) {
        super(props);

        this.state = {regions: [], isLoading: false};
    }

    async componentDidMount() {
        const {databaseId} = this.props.match.params;
        await this.loadRegions(databaseId);
    }

    async componentDidUpdate(prevProps: Readonly<RouteComponentProps<any, any>>, prevState: Readonly<IState>, snapshot?: any) {
        const prevDatabaseId = prevProps.match.params['databaseId'];
        const didDatabaseRouteChange = prevDatabaseId && prevDatabaseId !== this.props.match.params['databaseId'];
        if (didDatabaseRouteChange) {
           await this.loadRegions(this.props.match.params['databaseId'])
        }
    }

    async loadRegions(databaseId: string) {
        this.setState({regions: [], isLoading: true});
        const regionsAndTypesResponse: RegionTypeResponseModel = await regionService.getRegionsAndTypes(databaseId);
        const regions: RegionModel[] = Array.from(new Set(regionsAndTypesResponse.list.map((regionAndType) => regionAndType.region)))
            .map((regionName) => {
                return {name: regionName}
            });
        this.setState({regions: regions, isLoading: false});
    }

    render() {
        const {databaseId} = this.props.match.params;
        return (
            <div>
                <div className="text-3xl mb-10">{databaseId}</div>
                {this.state.isLoading && <LoadingSpinner/>}
                <div className="flex flex-wrap">
                    {this.state.regions.map((region, index) =>
                        <Card key={index} icon={<GlobeIcon className="w-8 h-8"/>} bodyContent={<></>} title={region.name}/>
                    )}
                    {this.state.regions.length === 0 && !this.state.isLoading && <div className="w-full text-center">No Regions</div>}
                </div>
            </div>
        );
    }
}
export default withRouter(Database);

interface IState extends ILoading {
    regions: RegionModel[];
}
