import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";

interface GlobalState {
    isLoading: boolean;
    setLoading: (newValue: boolean) => void;
    shouldShowFlyout: boolean;
    setFlyout: React.Dispatch<React.SetStateAction<boolean>>;
    flyoutTitle: string;
    setFlyoutTitle: React.Dispatch<React.SetStateAction<string>>;
    flyoutData: Record<string, any>;
    setFlyoutData: React.Dispatch<React.SetStateAction<{}>>;
}

const GlobalContext = createContext<GlobalState>({} as GlobalState);

export const GlobalProvider: React.FC = ({ children }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [shouldShowFlyout, setFlyout] = useState<boolean>(false);
    const [flyoutTitle, setFlyoutTitle] = useState<string>("");
    const [flyoutData, setFlyoutData] = useState<{}>({});

    const setLoading = useCallback((newState: boolean) => {
        setIsLoading(newState);
    }, []);

    const value = useMemo(
        () => ({
            isLoading,
            setLoading,
            shouldShowFlyout,
            setFlyout,
            flyoutTitle,
            setFlyoutTitle,
            flyoutData,
            setFlyoutData,
        }),
        [flyoutData, flyoutTitle, isLoading, setLoading, shouldShowFlyout]
    );

    return (
        <GlobalContext.Provider value={value}>
            {children}
        </GlobalContext.Provider>
    );
};

export const useGlobal = () => {
    return useContext(GlobalContext);
};
