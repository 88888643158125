import React, {Component} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {databaseService, itemService} from "../../Services/services";
import DataTable from 'react-data-table-component';
import {TableColumn} from "react-data-table-component/dist/src/DataTable/types";
import ILoading from "../Shared/ILoading";
import LoadingSpinner from "../Shared/LoadingSpinner";
import DatabaseModel from "../../Models/DatabaseModel";
import DataTableHelper from "../../Helpers/DataTableHelper";

class View extends Component<RouteComponentProps<any, any>, IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            baseRows: [],
            baseColumns: [],
            viewRows: [],
            viewColumns: [],
            isLoading: false
        };
    }

    async componentDidMount() {
        const {databaseId, regionName, typeName, viewName} = this.props.match.params;

        this.setState({isLoading: true});

        const database = await databaseService.get(databaseId);
        await this.loadBaseTable(database, regionName, typeName);
        await this.loadViewTable(database, regionName, typeName, viewName);

        this.setState({isLoading: false});
    }

    async componentDidUpdate(prevProps: Readonly<RouteComponentProps<any, any>>) {
        const prevDatabaseId = prevProps.match.params['databaseId'];
        const prevRegionName = prevProps.match.params['regionName'];
        const prevTypeName = prevProps.match.params['typeName'];
        const prevViewName = prevProps.match.params['viewName'];

        const {databaseId, regionName, typeName, viewName} = this.props.match.params;

        const didDatabaseRouteChange = prevDatabaseId && prevDatabaseId !== databaseId;
        const didRegionRouteChange = prevRegionName && prevRegionName !== regionName;
        const didTypeRouteChange = prevTypeName && prevTypeName !== typeName;
        const didViewRouteChange = prevViewName && prevViewName !== viewName;

        if (didDatabaseRouteChange || didRegionRouteChange || didTypeRouteChange || didViewRouteChange) {
            this.setState({isLoading: true});

            const database = await databaseService.get(databaseId);
            await this.loadBaseTable(database, regionName, typeName);
            await this.loadViewTable(database, regionName, typeName, viewName);

            this.setState({isLoading: false});
        }
    }

    async loadBaseTable(database: DatabaseModel, regionName: string, typeName: string) {
        this.setState({baseRows: [], baseColumns: []});

        const itemsResponseModel = await itemService.getItems(database.id, database.key, regionName, typeName, "null");

        const baseTable = DataTableHelper.getTable(itemsResponseModel.list, false);
        this.setState({baseRows: baseTable.rows, baseColumns: baseTable.columns});
    }

    async loadViewTable(database: DatabaseModel, regionName: string, typeName: string, viewName: string) {
        const viewItemsResponseModel = await itemService.getItemsFromView(database.id, database.key, regionName, typeName, viewName, "null");

        const viewTable = DataTableHelper.getTable(viewItemsResponseModel.list, false);
        this.setState({viewRows: viewTable.rows, viewColumns: viewTable.columns});
    }

    render() {
        const {viewName} = this.props.match.params;
        return (
            <div>
                <div className="text-3xl">{viewName}</div>
                <div>
                    {this.state.isLoading && <LoadingSpinner/>}
                    {!this.state.isLoading &&
                        <div className="lg:flex flex-row flex-wrap w-full">
                            <div className="mt-10 flex-1 lg:mr-5">
                                <div className="text-xl mb-5">Base data</div>
                                <DataTable
                                    columns={this.state.baseColumns}
                                    data={this.state.baseRows}
                                />
                            </div>
                            <div className="mt-10 flex-1 lg:mr-5">
                                <div className="text-xl mb-5">View data</div>
                                <DataTable
                                    columns={this.state.viewColumns}
                                    data={this.state.viewRows}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
export default withRouter(View);

interface IState extends ILoading {
    baseRows: any[];
    baseColumns: TableColumn<any>[];
    viewRows: any[];
    viewColumns: TableColumn<any>[];
}
