import React, { Component } from "react";
import Base from "./Components/Base/Base";
import { DatabaseInfoProvider } from "./Contexts/DatabaseInfo";
import httpClient from "./Services/Http/httpClient";

class App extends Component {
    constructor(props: any) {
        super(props);
        httpClient.defaults.headers.common["Gyxi-TokenKey"] =
            "33dc403e-7954-4c17-ae20-b8a65fe71bfd";
    }

    render() {
        return (
            <DatabaseInfoProvider>
                <Base />
            </DatabaseInfoProvider>
        );
    }
}

export default App;
