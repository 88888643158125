import { XIcon } from "@heroicons/react/outline";
import { useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { useGlobal } from "../../Contexts/Global";
import FlyoutContent from "../Container/FlyoutContent";

const Flyout: React.FC = () => {
    const { shouldShowFlyout, setFlyout, flyoutTitle } = useGlobal();
    const flyoutRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick = () => {
        setFlyout(false);
    };

    useOnClickOutside(flyoutRef, handleOutsideClick);

    return shouldShowFlyout ? (
        <div
            className={`w-full md:w-96 bg-white p-6 border-l-2 shadow-xl overflow-auto border-gray-200 absolute min-h-screen inset-y-0 right-0`}
            ref={flyoutRef}
        >
            <div className="flex justify-between">
                <div className="flex items-center">
                    <div className="text-2xl">{flyoutTitle}</div>
                </div>
                <div
                    className="cursor-pointer"
                    onClick={() => setFlyout(false)}
                >
                    <XIcon className="h-7 w-7 text-gray-500" />
                </div>
            </div>
            <FlyoutContent />
        </div>
    ) : null;
};

export default Flyout;
