import React, { useRef } from "react";
import { MenuItem } from "./MenuItem";
import { useOnClickOutside } from "usehooks-ts";
import { useMenuActions } from "../../../hooks/useMenuActions";
import { useGlobal } from "../../../Contexts/Global";
import { useTableData } from "../../../Contexts/TableData";

interface MenuProps {
    isOpen: boolean;
    outsideClickHandler: () => void;
    row: any;
}

export const Menu: React.FC<MenuProps> = ({
    isOpen,
    outsideClickHandler,
    row,
}) => {
    const { handleDelete, handleEdit } = useMenuActions();
    const { setLoading } = useGlobal();
    const { loadTableData } = useTableData();
    const menuRef = useRef<HTMLDivElement | null>(null);

    const deleteHandler = async () => {
        setLoading(true);

        await handleDelete(row.id);
        await loadTableData(true);

        setLoading(false);
    };

    const editHandler = async () => {
        outsideClickHandler();
        handleEdit(row);
    };

    useOnClickOutside(menuRef, outsideClickHandler);

    return (
        <div
            style={{ display: isOpen ? "initial" : "none" }}
            className="w-max text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white absolute z-10 right-5 top-8"
            ref={menuRef}
        >
            <MenuItem clickHandler={editHandler} text="Edit" />
            <MenuItem clickHandler={deleteHandler} text="Delete" />
        </div>
    );
};
