import React, { createContext, useContext, useMemo, useState } from "react";

interface DatabaseInfo {
    id: string;
    apiKey: string;
    regionName: string;
    typeName: string;
    partitionBy: string;
}

export interface DatabaseInfoState extends DatabaseInfo {
    updateDatabaseInfo: (data: DatabaseInfo) => void;
}

export const DatabaseInfoContext = createContext<DatabaseInfoState>(
    {} as DatabaseInfoState
);

export const DatabaseInfoProvider: React.FC = ({ children }) => {
    const [databaseId, setDatabaseId] = useState<string>("");
    const [key, setKey] = useState<string>("");
    const [region, setRegion] = useState<string>("");
    const [type, setType] = useState<string>("");
    const [partition, setPartition] = useState<string>("");

    const updateDatabaseInfo = ({
        id,
        apiKey,
        regionName,
        typeName,
        partitionBy,
    }: DatabaseInfo) => {
        setDatabaseId(id);
        setKey(apiKey);
        setRegion(regionName);
        setType(typeName);
        setPartition(partitionBy);
    };

    const databaseInfoValue = useMemo(
        () => ({
            id: databaseId,
            apiKey: key,
            regionName: region,
            typeName: type,
            partitionBy: partition,
            updateDatabaseInfo,
        }),
        [databaseId, key, region, type, partition]
    );

    return (
        <DatabaseInfoContext.Provider value={databaseInfoValue}>
            {children}
        </DatabaseInfoContext.Provider>
    );
};

export const useDatabaseInfo = () => {
    return useContext(DatabaseInfoContext);
};
