import React, { useEffect, useState } from "react";
import { flyoutEditTitle } from "../../Constants";
import { useGlobal } from "../../Contexts/Global";
import { useTableData } from "../../Contexts/TableData";
import { useDatabaseActions } from "../../hooks/useDatabaseActions";

interface FormField {
    name: string;
    value: string;
}

const Flyout: React.FC = () => {
    const [formFields, setFormFields] = useState<FormField[]>([]);
    const [defaultFormFieldValues, setDefaultFormFieldValues] = useState<
        FormField[]
    >([]);
    const { columns, loadTableData } = useTableData();
    const { saveItem } = useDatabaseActions();
    const { setLoading, setFlyout, flyoutData, flyoutTitle } = useGlobal();

    useEffect(() => {
        const formFieldsTemp: FormField[] = columns
            .filter((column) => column.name)
            .map((column) => {
                return {
                    name: column.name!.toString(),
                    value:
                        flyoutTitle === flyoutEditTitle
                            ? flyoutData[column.name!.toString()]
                            : "",
                };
            });
        setFormFields(formFieldsTemp);
        setDefaultFormFieldValues(JSON.parse(JSON.stringify(formFieldsTemp)));
    }, [columns, flyoutData, flyoutTitle]);

    const handleChange = (event: any, fieldName: string) => {
        let fieldToUpdate = formFields.find(
            (field) => field.name === fieldName
        );
        fieldToUpdate!.value = event.target.value;

        setFormFields([...formFields]);
    };

    const saveClickHandler = async () => {
        let payload = {};
        let shouldUpdate = false;
        formFields.forEach((field, index) => {
            const pair = { [field.name]: field.value };
            payload = { ...payload, ...pair };
            if (
                !shouldUpdate &&
                defaultFormFieldValues[index].value !== field.value
            ) {
                shouldUpdate = true;
            }
        });

        setLoading(true);
        setFlyout(false);
        if (shouldUpdate) {
            await saveItem(JSON.stringify(payload));
            await loadTableData(true);
        }

        setLoading(false);
    };

    return (
        <div>
            <div className="mt-8">
                {formFields.map((field, index) => {
                    return (
                        <div className="mb-4" key={index}>
                            <label className="block text-gray-700 text-sm mb-2">
                                {field.name}
                            </label>
                            <input
                                value={field.value}
                                onChange={(e) => handleChange(e, field.name)}
                                type="text"
                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                            />
                        </div>
                    );
                })}
                <button
                    className="h-10 w-full md:w-40 mt-2 rounded bg-gyxi-blue hover:bg-gyxi-blue-dark text-white"
                    onClick={saveClickHandler}
                >
                    Save
                </button>
            </div>
        </div>
    );
};

export default Flyout;
