import React, { useState } from "react";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import { TableRow } from "react-data-table-component";
import { Menu } from "./Menu/Menu";

interface ThreeDotsProps {
    row: TableRow;
}

export const ThreeDots: React.FC<ThreeDotsProps> = ({ row }) => {
    const [isMenuOpen, setMenu] = useState<boolean>(false);

    const onClickHandler = () => {
        setMenu(!isMenuOpen);
    };

    const outsideClickHandler = () => {
        setMenu(false);
    };

    return (
        <>
            <DotsVerticalIcon
                className="w-5 h-5 cursor-pointer"
                onClick={onClickHandler}
            />
            <Menu
                isOpen={isMenuOpen}
                outsideClickHandler={outsideClickHandler}
                row={row}
            />
        </>
    );
};
