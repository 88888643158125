import React from "react";

interface MenuItemProps {
    clickHandler: () => void;
    text: string;
}

export const MenuItem: React.FC<MenuItemProps> = ({ clickHandler, text }) => {
    return (
        <p
            onClick={clickHandler}
            className="w-full z-10 px-6 py-2 text-left border-b border-gray-200 cursor-pointer hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white"
        >
            {text}
        </p>
    );
};
