import DatabaseMockService from "./Mock/databaseMockService";
import DatabaseService from "./Server/databaseService";
import RegionService from "./Server/regionService";
import ViewService from "./Server/viewService";
import ItemService from "./Server/itemService";

const useMockServices = false;   //TODO to config

export const databaseService = useMockServices
    ? new DatabaseMockService()
    : new DatabaseService();
export const regionService = new RegionService();   // TODO create mock
export const viewService = new ViewService();
export const itemService = new ItemService();
