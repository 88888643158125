import IItemService from "../Interfaces/IItemService";
import ItemResponseModel from "../../Models/ItemResponseModel";
import httpClient from "../Http/httpClient";
import {AxiosRequestConfig} from "axios";

class ItemService implements IItemService {
    getItems(databaseId: string, databaseApiKey: string, regionName: string, typeName: string, partitionBy: string): Promise<ItemResponseModel> {
        const config: AxiosRequestConfig = {headers: {"Gyxi-ApiKey": databaseApiKey}};
        return httpClient
            .get<ItemResponseModel>(`https://${regionName}-list.gyxi.com/${databaseId}/${typeName}/${partitionBy}`, config)
            .then(response => response.data);
    }

    getItemsFromView(databaseId: string, databaseApiKey: string, regionName: string, typeName: string, viewName: string, partitionBy: string): Promise<ItemResponseModel> {
        const config: AxiosRequestConfig = {headers: {"Gyxi-ApiKey": databaseApiKey}};
        return httpClient
            .get<ItemResponseModel>(`https://${regionName}-list.gyxi.com/${databaseId}/${typeName}/view/${viewName}/${partitionBy}`, config)
            .then(response => response.data);
    }

    saveItem(payload: string, databaseId: string, databaseApiKey: string, regionName: string, typeName: string, partitionBy: string): Promise<SaveItemResponseModel> {
        const config: AxiosRequestConfig = {headers: {"Gyxi-ApiKey": databaseApiKey}};
        return httpClient
            .post(`https://${regionName}-save.gyxi.com/${databaseId}/${typeName}/${partitionBy}`, payload, config)
            .then(response => response.data);
    }

    deleteItem(id: string, databaseId: string, databaseApiKey: string, regionName: string, typeName: string, partitionBy: string): Promise<DeleteItemResponseModel> {
        const config: AxiosRequestConfig = {headers: {"Gyxi-ApiKey": databaseApiKey}};
        return httpClient
            .delete(`https://${regionName}-delete.gyxi.com/${databaseId}/${typeName}/${partitionBy}/${id}`, config)
            .then(response => response.data);
    }
}
export default ItemService;
