import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Databases from "./Databases";
import Database from "./Database";
import Region from "./Region";
import Type from "./Type";
import View from "./View";
import PageNotFound from "./PageNotFound";

class Container extends Component {
    render() {
        return (
            <div className="w-full p-10 bg-gray-50 min-h-calc-minus-header">
                <Switch>
                    <Route path="/databases/:databaseId/:regionName/:typeName/:viewName">
                        <View />
                    </Route>
                    <Route path="/databases/:databaseId/:regionName/:typeName">
                        <Type />
                    </Route>
                    <Route path="/databases/:databaseId/:regionName">
                        <Region />
                    </Route>
                    <Route path="/databases/:databaseId">
                        <Database />
                    </Route>
                    <Route path="/databases">
                        <Databases />
                    </Route>
                    <Route>
                        <PageNotFound />
                    </Route>
                </Switch>
            </div>
        );
    }
}
export default Container;
