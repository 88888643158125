import {
    TableColumn,
    TableRow,
} from "react-data-table-component/dist/src/DataTable/types";
import { Media } from "react-data-table-component";
import React from "react";
import { InputCell } from "../Components/Shared/InputCell";
import { ThreeDots } from "../Components/Shared/ThreeDots";

const ID_FIELD = "id";

class DataTableHelper {
    public static getTable(
        responseModelList: any[],
        shouldAddActions: boolean
    ): ITableStructure {
        if (!responseModelList || responseModelList.length === 0)
            return { columns: [], rows: [] };

        let allObjectsProps: string[] = [];
        responseModelList.forEach((item) => {
            Object.keys(item).forEach((prop) => {
                if (!allObjectsProps.includes(prop)) {
                    if (prop === ID_FIELD) {
                        allObjectsProps.unshift(prop);
                    } else {
                        allObjectsProps.push(prop);
                    }
                }
            });
        });

        let columns: TableColumn<any>[] = allObjectsProps.map((key, index) => {
            return {
                name: key,
                selector: (row: any) => row[key],
                hide: index >= 3 ? Media.MD : 0,
                cell: (row, id) =>
                    React.createElement(InputCell, {
                        id,
                        row,
                        fieldName: key,
                        isReadOnly: key === ID_FIELD,
                        shouldShowLoader:
                            Math.round(allObjectsProps.length / 2) === index,
                    }),
            };
        });

        if (shouldAddActions) {
            columns.push({
                cell: (row: TableRow) =>
                    React.createElement(ThreeDots, {
                        row,
                    }),
                allowOverflow: true,
                button: true,
                width: "50px",
            });
        }

        return { columns: columns, rows: responseModelList };
    }
}

export default DataTableHelper;

interface ITableStructure {
    columns: TableColumn<any>[];
    rows: any[];
}
